import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .mainText {
    font-family: 'Patua One', cursive;
  }
  .text {
    font-family: 'Cabin', sans-serif;
  }
  .description {
    font-family: 'Sanchez', serif;
  }
`;

export default GlobalStyle;