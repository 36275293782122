import React, { useState, useEffect } from 'react';
import './NavigationBar.css';
import Scroll from 'react-scroll';
import { FaHome, FaKeyboard } from 'react-icons/fa';
import { GoPerson } from 'react-icons/go';
import { TiPhoneOutline } from 'react-icons/ti';

const { Link, scrollSpy } = Scroll;
const iconSize = 40;

const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    scrollSpy.update();
  }, []);

  const toggleNavBar = () => {
    setNavOpen((prevNavOpen) => !prevNavOpen);
  };

  return (
    <div className="navigationContainer">
      <div
        className="burgerButton bbLeft"
        title="Navigation Menu"
        onClick={toggleNavBar}
      >
        <hr id={navOpen ? "topBBL" : ""} />
        <hr id={navOpen ? "middleBBL" : ""} />
        <hr id={navOpen ? "bottomBBL" : ""} />
      </div>
      <div className={navOpen ? "navItems" : "navItems hideBar"}>
        <ul>
          <li>
            <Link activeClass="active" to="homeBlock" spy smooth offset={0} duration={500}>
              <FaHome size={iconSize} />
            </Link>
          </li>
          <li>
            <Link activeClass="active" to="aboutMeBlock" spy smooth offset={0} duration={500}>
              <GoPerson size={iconSize} />
            </Link>
          </li>
          <li>
            <Link activeClass="active" to="projectsBlock" spy smooth offset={0} duration={500}>
              <FaKeyboard size={iconSize} />
            </Link>
          </li>
          <li>
            <Link activeClass="active" to="contactBlock" spy smooth offset={0} duration={500}>
              <TiPhoneOutline size={iconSize} />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;