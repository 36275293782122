import React, { useState, useEffect, useRef } from 'react';
import './Projects.css';
import ProjectNavigation from '../Navigation/ProjectNavigation';

const Projects = () => {
  const projects = [
    { id: "A*", title: "A* Search Algorithm Visualization", video: "https://www.youtube.com/embed/Hqy4vvQiPrg" },
    { id: "ChessGroundwork", title: "Chess Groundwork for a Mobile Application", video: "https://www.youtube.com/embed/KCA7nnTum1g" },
    { id: "Jarvis", title: "J.A.R.V.I.S for MacOS (voice assistant)", video: "https://www.youtube.com/embed/duVN9QidPXQ" },
    { id: "SnakeDodge", title: "Snake Dodge built in Java", video: "https://www.youtube.com/embed/Nj4njnuYDVs" },
  ];

  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [isInProjectsSection, setIsInProjectsSection] = useState(false);
  const imgHolders = useRef([]);
  const projectsBlockRef = useRef(null);

  const projectColors = ['#63B133', '#587557', '#4EEEEB', '#4641D8'];
  const boxShadowStyles = [
    "-3px -3px 8px #b7e29c90, 3px 3px 18px #213b1180",
    "-3px -3px 8px #a8bea790, 3px 3px 18px #161d1680",
    "-3px -3px 8px #d1fafa90, 3px 3px 18px #10a2a080",
    "-3px -3px 8px #c1c0f290, 3px 3px 18px #18166a80",
  ];

  const handleScroll = () => {
    const screenHeight = window.innerHeight;
    const yOffset = window.pageYOffset;

    // Get the start and end positions of the Projects section
    const projectsStart = projectsBlockRef.current.offsetTop;
    const projectsEnd = projectsStart + screenHeight * projects.length;

    // Determine if the user is within the Projects section
    setIsInProjectsSection(yOffset >= projectsStart && yOffset < projectsEnd);

    projects.forEach((_, index) => {
      const holder = imgHolders.current[index];
      if (!holder) return;

      let background = '';
      let boxShadow = '';

      if (
        yOffset >= projectsStart + screenHeight * index &&
        yOffset < projectsStart + screenHeight * (index + 1)
      ) {
        setCurrentProjectIndex(index);
        background = projectColors[index];
        boxShadow = boxShadowStyles[index];
      } else {
        background = '#3066BE'; // Default color
        boxShadow = 'none'; // Remove box shadow
      }

      holder.style.background = background;
      holder.style.boxShadow = boxShadow;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const prevId = currentProjectIndex > 0 ? projects[currentProjectIndex - 1].id : null;
  const nextId =
    currentProjectIndex < projects.length - 1
      ? projects[currentProjectIndex + 1].id
      : null;

  return (
    <div id="projectsBlock" ref={projectsBlockRef}>
      {projects.map(({ id, title, video }, index) => (
        <div className="projHolder" id={id} key={index}>
          <div
            className="projImageHolder"
            ref={(el) => (imgHolders.current[index] = el)}
          >
            <div className="text vidText">{title}</div>
            <div className="vidWrapper">
              <div className="vidHolder">
                <iframe
                  title={title}
                  width="100%"
                  height="100%"
                  src={video}
                  frameBorder="0"
                  scrolling="yes"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      ))}

      {isInProjectsSection && (
        <ProjectNavigation
          projNavOpen={isInProjectsSection}
          showProjUpArrow={!!prevId}
          showProjDownArrow={!!nextId}
          prevId={prevId}
          nextId={nextId}
        />
      )}
    </div>
  );
};

export default Projects;