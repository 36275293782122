import React from 'react';
import './Home.css';
import styled from 'styled-components';
import ProfileImg from '../../images/profile.jpg';

const ProfileImage = styled.img`
  width: 50%;
  height: 50%;
  border-radius: 100%;
`;

const ProfileText = styled.div`
  font-size: 5vw;
  font-weight: bold;
`;

const Home = () => {
  return (
    <div className="blockContainer">
      <div id="homeBlock">
        <div className="mainText textHolder">
          <ProfileImage src={ProfileImg} alt="Rooshhil Patel" />
          <ProfileText>Rooshhil Patel</ProfileText>
        </div>
      </div>
    </div>
  );
};

export default Home;