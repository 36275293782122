import React, { useState, useEffect } from 'react';
import GlobalStyle from './fonts/GlobalFonts';
import './App.css';
import Home from './components/Home/Home';
import AboutMe from './components/AboutMe/AboutMe';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import Navigation from './components/Navigation/NavigationBar';

const App = () => {
  const [backgroundColor, setBackgroundColor] = useState('#E74A32'); // Default color for Home
  const [showStars, setShowStars] = useState(false);
  const [aboutMeVisible, setAboutMeVisible] = useState(false); // Track AboutMe visibility
  const projectColors = ['#63B133', '#587557', '#4EEEEB', '#4641D8']; // Colors for each project

  useEffect(() => {
    const handleScroll = () => {
      const yPos = window.pageYOffset;
      const screenHeight = window.innerHeight;

      const projects = document.querySelectorAll('#projectsBlock .projHolder'); // Get all projects dynamically
      const projectsCount = projects.length; // Number of projects
      const projectsHeight = projectsCount * screenHeight; // Total height of ProjectsBlock

      const sections = [
        { id: 'homeBlock', triggerStart: 0, triggerEnd: 0.5 * screenHeight, color: '#E74A32' },
        { id: 'aboutMeBlock', triggerStart: 0.5 * screenHeight, triggerEnd: 2 * screenHeight, color: '#3066BE' },
        {
          id: 'projectsBlock',
          triggerStart: 2 * screenHeight,
          triggerEnd: 2 * screenHeight + projectsHeight,
          color: null,
        }, // Handle dynamically
        {
          id: 'contactBlock',
          triggerStart: 2 * screenHeight + projectsHeight,
          triggerEnd: Infinity,
          color: '#755875',
        },
      ];

      let newBackgroundColor = null;

      for (let i = 0; i < sections.length; i++) {
        const section = document.getElementById(sections[i].id);
        if (!section) continue;

        if (yPos >= sections[i].triggerStart && yPos < sections[i].triggerEnd) {
          // Special case for Projects
          if (sections[i].id === 'projectsBlock') {
            const sectionStart = sections[i].triggerStart;
            const projectIndex = Math.floor((yPos - sectionStart) / screenHeight); // Determine current project
            newBackgroundColor = projectColors[projectIndex] || '#3066BE';
          } else {
            newBackgroundColor = sections[i].color;
          }

          if (sections[i].id === 'aboutMeBlock') {
            setShowStars(true);
            setAboutMeVisible(true);
          } else {
            setShowStars(false);
            setAboutMeVisible(false);
          }
          break;
        }
      }

      // Update background color only if it changes
      if (newBackgroundColor && newBackgroundColor !== backgroundColor) {
        setBackgroundColor(newBackgroundColor);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [backgroundColor]);

  return (
    <>
      <GlobalStyle />
      <div className="mainContainer" style={{ backgroundColor }}>
        <Navigation />
        <div id="homeBlock">
          <Home />
        </div>
        <div
          id="aboutMeBlock"
          className={aboutMeVisible ? 'show' : ''}
        >
          <AboutMe showStars={showStars} />
        </div>
        <div id="projectsBlock">
          <Projects />
        </div>
        <div id="contactBlock">
          <Contact />
        </div>
      </div>
    </>
  );
};

export default App;