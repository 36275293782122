import React from 'react';
import './Contact.css';
import { FaYoutube, FaSoundcloud, FaLinkedin, FaGithub } from 'react-icons/fa';

const iconSize = 50;

const Contact = () => {
  return (
    <div id="contactBlock">
      <div id="woodHolder">
        <div id="woodBackground"></div>
      </div>

      <div id="emailHolder">
        <div id="email" className="text">
          <a
            href="mailto:rushil.patel14@gmail.com?Subject=Hello%20Rooshhil"
            title="Email Rooshhil!"
            aria-label="Email Rooshhil"
            target="_top"
          >
            Rushil.patel14@gmail.com
          </a>
        </div>
      </div>

      <div className="text socialHolder">
        <div className="social">
          <div className="icon linkedin" title="LinkedIn">
            <a
              href="https://www.linkedin.com/in/rooshhilpatel"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit LinkedIn Profile"
            >
              <FaLinkedin size={iconSize} />
            </a>
          </div>
          <div className="icon github" title="Github">
            <a
              href="https://github.com/RooshhilPatel"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit GitHub Profile"
            >
              <FaGithub size={iconSize} />
            </a>
          </div>
          <div className="icon youtube" title="YouTube">
            <a
              href="https://www.youtube.com/channel/UCIOVvX83akcwueAKprDA7Xg"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit YouTube Channel"
            >
              <FaYoutube size={iconSize} />
            </a>
          </div>
          <div className="icon soundcloud" title="SoundCloud">
            <a
              href="https://soundcloud.com/rrushbeats"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit SoundCloud Profile"
            >
              <FaSoundcloud size={iconSize} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;