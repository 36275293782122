import React from 'react';
import './AboutMe.css';
import { FaHandPointDown } from 'react-icons/fa';

const AboutMe = ({ showStars }) => {
  return (
    <div id="aboutMeBlock" className={`aboutMe ${showStars ? "" : "hideStars"}`}>
      <div className="text descriptionText">
        &lt; Hello World! / &gt; <br /><br />
        <div className="description">
          Let me tell you a little about myself. <br />
          My name is Rooshhil Patel and I am a <br />
          Software Engineer, an Application Developer and,<br />
          most importantly, a Problem Solver. <br /> <br />
          I see Computer Science as the modern day alchemy, <br />
          converting nothing into something! <br /><br />
          Aside from compiling beautiful lines of code, <br />
          I enjoy working out, dancing, DJ-ing and producing music. <br />
          My free time is infused with diving into <br />
          topics like quantum physics and entrepreneurship.<br /><br />
          I want to give the world unprecedented technology,<br />
          of course, while living life to its fullest!<br /><br />
          Check out the cool stuff I've done below. <FaHandPointDown size="30" />
        </div>
      </div>
    </div>
  );
};

export default AboutMe;