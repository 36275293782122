import React from 'react';
import './ProjectNavigation.css';
import Scroll from 'react-scroll';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';

const { scroller } = Scroll;
const iconSize = 40;

const ProjectNavigation = ({ projNavOpen, showProjUpArrow, showProjDownArrow, prevId, nextId }) => {
  const scrollToElement = (id) => {
    if (id) {
      scroller.scrollTo(id, {
        duration: 500,
        smooth: true,
        offset: 0,
      });
    }
  };

  return (
    <div className={`projNavItems ${projNavOpen ? 'showProjBar' : 'hideProjBar'}`}>
      <ul>
        {showProjUpArrow && (
          <li title="Previous Project">
            <button
              onClick={() => scrollToElement(prevId)}
              className="scrollButton"
              aria-label="Scroll to previous project"
            >
              <FaArrowCircleUp size={iconSize} />
            </button>
          </li>
        )}
        {showProjDownArrow && (
          <li title="Next Project">
            <button
              onClick={() => scrollToElement(nextId)}
              className="scrollButton"
              aria-label="Scroll to next project"
            >
              <FaArrowCircleDown size={iconSize} />
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ProjectNavigation;